import { createAction } from "@reduxjs/toolkit";

export const UserActionTypes = {
  USER_LOGIN: 'user/USER_LOGIN',
  USER_LOGOUT: 'user/USER_LOGOUT',
  USER_LOGIN_ERROR: 'user/USER_LOGIN_ERROR',
  USER_GET: 'user/USER_GET',
  USER_PROFILE_UPDATE: 'user/USER_PROFILE_UPDATE',
};

export const UserActions = {
  login: (username, password) => createAction(UserActionTypes.USER_LOGIN, { username, password }),
};