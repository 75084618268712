import Header from '../../Common/Header';
import Footer from '../../Common/Footer/Footer';
import './Login.scss';
import { UserActions } from '../../../actions/userActions';
import { connect } from 'react-redux';
import LoginForm from '../../../forms/login.form';

const Login = (props) => {
  return (
    <>
      <Header />
      <div className="siteCss">
        <div id="login-row" className="row justify-content-center align-items-center">
          <LoginForm handleSubmit={props.userLogin} />
          <button onClick={() => props.userLogin('dd', 'ddd')}>Click</button>
        </div>
      </div>
      <Footer />
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (username, password) => dispatch(UserActions.login(username, password))
  }
}

export default connect(null, mapDispatchToProps)(Login);
