import Header from '../../Common/Header';
import Footer from '../../Common/Footer/Footer';
import './Gallery.scss';

const Home = () => {
  return (
    <>
      <Header />
      <div className="siteCss">
        <div className="gallery">
          <h1>Gallery</h1>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Home;
