import { UserActionTypes } from "../actions/userActions";

const initialState = {
  isLoggedIn: false,
  errors: {},
  activeUser: [],
  loggedInUsers: [
    {
      name: 'Admin',
      username: 'admin',
      password: 'Nonunanu123!@#',
    }
  ]
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserActionTypes.USER_LOGIN:
      console.log(state, action);
      return { ...state, isLoggedIn: true, activeUser: action.payload, errors: {} };
    case UserActionTypes.USER_LOGOUT:
      return { ...state, isLoggedIn: false, activeUser: {}, errors: {} };
    case UserActionTypes.USER_LOGIN_ERROR:
      return { ...state, errors: action.payload };
    case UserActionTypes.USER_GET:
      return { ...state, activeUser: action.payload };
    case UserActionTypes.USER_PROFILE_UPDATE:
      return { ...state, activeUser: { ...state.user, ...action.payload } };
    default:
      return state;
  }
}

export default userReducer;