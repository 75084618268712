import React from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import { authValidator } from '../validations/auth.validator';

const LoginForm = (props) => {
  const { handleSubmit, err } = props;
  return (
    <Formik
      initialValues={{ email: "admin", password: "Nonunanu123!@#" }}
      onSubmit={(values, actions) => {
        console.log(values);
        handleSubmit(values.email, values.password)
        // .then((res) => {
        //   actions.setSubmitting(false);
        // });
      }}
      validationSchema={authValidator.authLoginSchema}
      render={({ handleSubmit, errors, touched, isSubmitting }) => (
        <form className="user" autoComplete="false" onSubmit={handleSubmit} noValidate>
          {err && <span className="main-error">{err.message}</span>}
          <div className="form-group">
            <Field
              component="input"
              type="email"
              className="form-control form-control-user"
              aria-describedby="emailHelp"
              id="username"
              placeholder="Enter username"
              autoComplete="off"
              name="username" />
            <div className="error-box"><span className={"error " + (errors.email && touched.email ? "show" : "")}>{errors.email}</span></div>
          </div>
          <div className="form-group">
            <Field
              component="input"
              type="password"
              className="form-control form-control-user"
              placeholder="Enter password"
              id="password"
              name="password" />
            <div className="error-box"><span className={"error " + (errors.password && touched.password ? "show" : "")}>{errors.password}</span></div>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox small">
              <Field component="input" type="checkbox" className="custom-control-input" id="customCheck" />
              <label className="custom-control-label" htmlFor="customCheck">Remember Me</label>
            </div>
          </div>
          <button type="submit" className="btn btn-primary btn-user btn-block" disabled={isSubmitting}>Login</button>
        </form>
      )} />
  );
}

const mapStoreToProps = (store) => {
  return {
    err: store.user.errors
  }
}

export default connect(mapStoreToProps)(LoginForm);